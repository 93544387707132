import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import TwoAndOne from "../../components/twoAndOne"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      flyer1: file(relativePath: { eq: "beratung/lfach_brosch.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flyer2: file(relativePath: { eq: "beratung/pix.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anna: file(relativePath: { eq: "mitarbeiter/anna.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Angehörige" />
      <Header siteTitle="Beratung" subTitle="Angehörige" />
      <TwoAndOne>
        <div className="content">
          <p>
            Glücksspielsucht und Medienabhängigkeit sind Erkrankungen. Das
            erleben nicht nur die Betroffenen, sondern vor allem auch die
            Menschen in ihrem sozialen Umfeld. Dazu gehören neben den engen
            Familienangehörigen wie Partnern, Kindern, Eltern und Geschwistern
            auch Freunde und Arbeitskollegen. Die Nähe zum Betroffenen wird für
            viele Angehörigen zu einer schweren Last, wenn die Abhängigkeit das
            Leben bestimmt.
          </p>
          <p>
            <b>Für Angehörige von Glücksspielern bedeutet dies:</b>
          </p>
          <ul>
            <li>
              Sie machen sich Sorgen, weil sie nicht mehr wissen, wie die Miete,
              die Schulden, oder der tägliche Einkauf bezahlt werden sollen.
            </li>
            <li>
              Sie haben Angst vor der Zukunft, sorgen sich um das Leben ihres
              Partners, um ihre eigene Gesundheit oder das Wohlergehen ihrer
              Kinder.
            </li>
            <li>
              Gespräche mit ihrem Partner enden häufig in Streitigkeiten über
              die finanzielle Situation und führen zu Schuldzuweisungen oder
              emotionalen Verletzungen.
            </li>
            <li>
              Sie fühlen sich allein gelassen mit ihren Ängsten und Sorgen und
              verlieren ihr Vertrauen, da der Betroffene trotz vieler
              Versprechungen sein Spielverhalten nicht verändert.
            </li>
            <li>
              Sie finden kein Verständnis und keine Unterstützung im sozialen
              Umfeld oder schämen sich zu sehr, um von den Problemen zu
              erzählen.
            </li>
            <li>
              Sie erleben ihren Partner oft geistig abwesend, auch wenn er neben
              ihnen sitzt.
            </li>
            <li>
              Sie wissen nicht mehr, was und wann sie ihrem Partner glauben
              können und wie sie mit ihm und der Situation umgehen sollen.
            </li>
          </ul>
          <p>
            <b>Eltern als Angehörige</b>
          </p>
          <p>
            Abhängigkeit entwickelt sich unabhängig vom Alter. Weder Senioren,
            noch Jugendliche sind vor der Entwicklung einer
            Abhängigkeitserkrankung geschützt. Doch die Probleme von Erwachsenen
            und jugendlichen Abhängigen sind aufgrund der unterschiedlichen
            Lebenssituation andere. Somit unterscheidet sich auch die
            Problematik der (mit-) betroffenen Angehörigen.
          </p>

          <p>
            <b>Für Eltern stehen zumeist andere Probleme im Vordergrund:</b>
          </p>
          <ul>
            <li>
              Sie haben Sorge um die persönliche und berufliche Entwicklung der
              Betroffenen.
            </li>
            <li>
              Sie fühlen sich verantwortlich für die Situation und leiden
              oftmals unter Schuld und Schamgefühlen.
            </li>
            <li>Sie finden keinen Zugang mehr zur Welt ihrer Kinder.</li>
            <li>
              Sie leiden unter den ständig wiederkehrenden Auseinandersetzungen
              und Streitigkeiten.
            </li>
            <li>Sie fühlen sich hilflos der Situation ausgeliefert.</li>
            <li>
              Sie sind aber gleichzeitig, aufgrund des Alters der Betroffenen,
              für deren Unterhalt verantwortlich.
            </li>
          </ul>
        </div>
        <div className="content">
          <div className="notification is-primary">
            <h4 className="title">
              Informationsbroschüren für Angehörige von Glücksspielern
            </h4>
            <div className="columns">
              <div className="column is-one-third">
                <Img fluid={data.flyer1.childImageSharp.fluid} />
              </div>
              <div className="column">
                <h5 className="title">Nichts geht mehr!?</h5>
                <h6 className="subtitle">Broschüre für Angehörige</h6>
                <p>
                  Herausgeber:
                  <br />
                  Landesfachstelle Glücksspielsucht NRW
                  <br />
                  <a href="Herunterladen">
                    <span className="icon is-small">
                      <i className="fas fa-download"></i>
                    </span>
                    <span> Herunterladen</span>
                  </a>
                </p>
              </div>
            </div>
            <hr />
            <div className="columns">
              <div className="column is-one-third">
                <Img fluid={data.flyer2.childImageSharp.fluid} />
              </div>
              <div className="column">
                <h5 className="title">Glücksspielsucht</h5>
                <h6 className="subtitle">Informationen zur Glücksspielsucht</h6>
                <p>
                  Herausgeber: BZGA
                  <br />
                  <a href="Herunterladen">
                    <span className="icon is-small">
                      <i className="fas fa-download"></i>
                    </span>
                    <span> Herunterladen</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="notification is-primary">
            <h4 className="title">Unser Angebot für Angehörige</h4>
            <p>
              Wir bieten Ihnen die Möglichkeit in Einzel-, Paar- und
              Familiengesprächen Ihre Probleme zu thematisieren. In den
              therapeutisch angeleiteten Gesprächsgruppen für Angehörige haben
              Sie die Möglichkeit sich in dieser schwierigen Phase begleiten zu
              lassen.
            </p>

            <p>
              <b>Wir unterstützen Sie durch:</b>
            </p>
            <ul>
              <li>Informationen zur Glücksspielsucht</li>
              <li>
                Beratung im Einzelkontakt zur individuellen Lebenssituationen
              </li>
              <li>
                Informationen und Unterstützung in konkreten Problemsituationen
              </li>
              <li>
                Entwicklung eigener Verhaltenssicherheit im Umgang mit der
                Glücksspielsucht
              </li>
              <li>Austausch mit anderen Angehörigen</li>
            </ul>
          </div>
          <h4 className="title">Ansprechpartnerin</h4>
          <div className="columns">
            <div className="column is-one-third">

            <Img fluid={data.anna.childImageSharp.fluid} />



            </div>
            <div className="column ">
              <h5 className="title">Anna Müller-Bödige</h5>
              <h6 className="subtitle">
                Dipl.-Sozialpädagogin, Sozial- und Suchttherapeutin (VT)
              </h6>
              <p className="is-size-7">
                <a href="mailto:mueller.boedige@ak-spielsucht.de">
                  <span class="icon">
                    <i class="fas fa-envelope"></i>
                  </span>
                  mueller.boedige@ak-spielsucht.de
                </a><br/>
                <span class="icon">
                  <i class="fas fa-phone"></i>
                </span>
                02303 89669
              </p>
       
            </div>
          </div>
        </div>
      </TwoAndOne>
    </Layout>
  )
}

export default IndexPage
